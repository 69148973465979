import React, { useEffect, useRef, useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./Routes/Layout";
import routes from "./Routes/Index";
import './App.scss';
import Login from './Login/Login';
import Page404 from './Componets/Page404/Page404';
import Page500 from './Componets/Page500/Page500';
import { jwtDecode } from 'jwt-decode';


function App() {
  const [isLogin, setIsLogin] = useState(localStorage.getItem('ID') ? true : false);
  const inactivityTimeout = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsLogin(!!sessionStorage.getItem('header'));
    }, 100);

    return () => clearInterval(intervalId);
  }, []);
  
  useEffect(() => {
    if (window.location.pathname === "/" || window.location.pathname === "/login") {
      sessionStorage.removeItem("header");
      localStorage.clear();
    }

    const handleActivity = () => {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = setTimeout(() => {
        sessionStorage.removeItem("header");
        localStorage.clear();
        setIsLogin(false);
        checkToken();
      }, 10 * 60 * 60 * 1000);
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    return () => {
      clearTimeout(inactivityTimeout.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);


  const checkToken = () => {
    const token = localStorage.getItem('ID');
    if (token) {
      const decodedToken = jwtDecode(token, "1234567890123456789");
      const currentTime = Date.now() / 1000;
      setIsLogin(decodedToken.exp >= currentTime);
    } else {
      setIsLogin(false);
    }
  };


  const router = createBrowserRouter([
    {
      element: isLogin ?
        <Layout />
        : <Login />,
      errorElement: window.location.pathname === '500' ? <Page500 /> : <Page404 />,
      children: routes
    }
  ]);

  return <RouterProvider router={router} />;
}

export default App;
